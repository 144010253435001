import React, { useEffect, useState, type ReactNode } from "react";
import { swipeGestureForMobile, appVisibility } from "@lib/store";

type GestureAndPageTrackerProps = {
  pathname: string;
  children?: ReactNode;
  className?: string;
};

const GestureAndPageTracker: React.FC<GestureAndPageTrackerProps> = ({
  pathname = "",
  className = "",
  children,
}) => {
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  // Updating screen
  useEffect(() => {
    const updateWindowSize = () => {
      if (window.innerWidth < 1030) {
        setIsSmallScreen(false);
      } else {
        setIsSmallScreen(true);
      }
      if (window.innerWidth < 680) {
        appVisibility.set({ isMobileView: true });
      } else {
        appVisibility.set({ isMobileView: false });
      }
    };
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);
    return () => {
      window.removeEventListener("resize", updateWindowSize);
    };
  }, []);

  useEffect(() => {
    return () => {
      localStorage.setItem("previousPage", pathname);
    };
  }, [pathname]);

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 80;

  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe || isRightSwipe)
      if (isLeftSwipe) {
        swipeGestureForMobile.set({ swipe: "left" });
      } else {
        swipeGestureForMobile.set({ swipe: "right" });
      }
  };
  if (true) {
    return (
      // Commented out gesture swipe-to-open-sidemenu in mobile view
      <div
        className={className}
        // onTouchStart={onTouchStart}
        // onTouchMove={onTouchMove}
        // onTouchEnd={onTouchEnd}
        >
        {children}
      </div>
    );
  } else {
    return <>{children}</>;
  }
};
export default GestureAndPageTracker;
